import React from 'react';
import Wordmark from '../assets/logos/wordmark.svg';
import Burger from './burger';
import '../styles/nav.scss';

const Nav = ({ children, background }) => {
    return (
        <nav className={"nav " + background}>
            <a href="/" className="logo">
                <Wordmark />
            </a>
            <Burger />
        </nav>
    )

}

export default Nav