import React from 'react';
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import PageHeading from '../../components/page-heading.js';
import '../../styles/article.scss';
import Contact from '../../components/contact.js';

const Article = ({data}) => {
    return (
        <Layout background="stone">
            <SEO 
              title="Black Friday is around the corner, is your Shopify store ready?"
              description="Black Friday is coming! Read this post to discover how to get your Shopify store ready to convert the rush of shoppers into real sales."
              image={data.file.childImageSharp.gatsbyImageData}
              article={true}/>
            <PageHeading h1="Black Friday is around the corner, is your Shopify store ready?" h2="Blog" blurb="Black Friday is coming! Read this post to discover how to get your Shopify store ready to convert the rush of shoppers into real sales." />
            <section className="ff article">
                <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Neon Black Friday sign" />
            </section>
            <section className="ff article">
                <p>Black Friday is right around the corner, so it’s time for e-commerce businesses to gear up. This season, a staggering <a href="https://www.takepayments.com/blog/online-and-tech/how-to-get-customers-to-put-more-in-their-basket/" target="_blank" rel="noreferrer">51% of UK adults are planning to spend</a>, and last year <a href="https://www.websitebuilderexpert.com/news/shopify-black-friday-sales/" target="_blank" rel="noreferrer">Shopify merchants alone made $9.3 billion over Black Friday weekend</a>. To make sure you’re ready for Black Friday, here’s a quick, actionable final touch guide to prepare your Shopify store for the busiest shopping day of the year.</p>
                <h3>1. Speed up and optimise your site 🚀</h3>
                <p>When it comes to Black Friday, speed sells. <a href="https://digitalpals.com/shopify-black-friday-2024-effective-strategies-to-boost-your-sales/" target="_blank" rel="noreferrer">A slow website will lead to a loss of sales</a>, so here’s some quick wins to make sure your load speed is optimised:</p>
                <ul>
                    <li><strong>Compress Images:</strong> Use Shopify’s built-in tools to reduce file sizes without losing quality. </li>
                    <li><strong>Disable Unused Plugins:</strong> Remove any old apps to help improve load times.</li>
                    <li><strong>Simplify Animations:</strong> Excessive animations, such as fade ins or pop-ups on load, can slow down your site, particularly on mobile devices. You want all assets above the fold to snap in as quickly as possible.</li>
                </ul>
                <p>Use <a href="https://developers.google.com/speed/pagespeed/insights/" target="_blank" rel="noreferrer">Google’s Page Insights tool</a> to know how your site is performing today and use it to track improvements over time.</p>
                <p>Nowadays, most Black Friday shoppers are on their phones, so mobile optimisation is a must!</p>
                <ul>
                    <li><strong>Test Responsiveness:</strong> Most Shopify themes are mobile-friendly out-of-the-box, but double-check for any layout issues on both iOS and Android, if you can. Tools like <a href="https://browserstack.com/" target="_blank" rel="noreferrer">BrowserStack</a> are great for this if you don't have the budget for a full-blown device lab.</li>
                </ul>
                <h3>2. Perfect your checkout process 📈</h3>
                <ul>
                    <li>Make it as easy for people to buy your product/service as possible! Enable express checkout options like Apple Pay, Google Pay, or Shop Pay to simplify the checkout experience, and in turn reduce cart abandonment.</li>
                    <li>Set up abandoned cart emails to catch out any sales left on the table. Reminders can nudge shoppers back to complete their purchases. <a href='https://www.bigcommerce.com/articles/ecommerce/abandoned-cart-emails/' target="_blank" rel="noreferrer">Abandoned cart emails generate an average of almost double typical marketing email open rates</a>, so make the most of this top tip!</li>
                    <li>Test your payment gateways by running test purchases, you need to make sure every payment option works smoothly – no surprises needed over the weekend!</li>
                </ul>
                <h3>3. Prep your Shopify apps 🔧</h3>
                <p><strong>Inventory management apps</strong></p>
                <p>Overselling can be a nightmare. Make sure your stock management apps are up-to-date and synced to avoid any stock issues. Also check whether or not your inventory policies are ideal for your business, do you want to accept oversells during the period? Review the “Continue selling when out of stock” setting at the variant level.</p>
                <p><strong>Customer service tools.</strong></p>
                <p>Your customer support channels (live chat, email, etc.) need to be ready to handle increased inquiries. Do a quick test to make sure they are functioning correctly.</p>
                <p><strong>Discount and sales apps.</strong></p>
                <p>Check that your discount codes and promotions are ready and easy for customers to find. Also, make sure expiration dates and limitations are clear to reduce the amount of frustrated customers.</p>
                <h3>4. Enhance your conversion with on-site marketing 🚧</h3>
                <p>Bold banners and strategic pop-ups can be fantastic for highlighting deals and encouraging customers to stay.</p>
                <p>We recommend creating a Black Friday collection and putting it at the top level of your navigation on both Desktop and Mobile. Users will be looking for this to know they’re getting the best deals your site offers</p>
                <h3>4. Prep your email marketing and social media ⚡️</h3>
                <p>Plan out your Black Friday email sequence and maximise engagement. This could be done by using a well-timed series like so: </p>
                <ul>
                    <li><strong>Teaser email:</strong> A little preview to get customers excited</li>
                    <li><strong>Early access:</strong> Why not treat the loyal customers to early access to some of your deals via a discount code? </li>
                    <li><strong>Last chance:</strong> Give your customers a little reminder before the sale ends.</li>
                </ul>
                <p>Don’t forget to coordinate your social media. Schedule posts to go live on Black Friday and automate replies to common questions to save time.</p>
                <h3>Conclusion 🛒</h3>
                <p>All in all, make sure you are:</p>
                <ul>
                    <li>Speed testing your Shopify site</li>
                    <li>Enabling all major payment gateway providers</li>
                    <li>Checking your apps are ready to go (and removing those that are no longer needed)</li>
                    <li>Confirming inventory levels</li>
                    <li>Enhancing your conversion with on-site marketing</li>
                    <li>Prepping your email marketing and social media</li>
                </ul>
                <p>Want to read more about the last-minute Shopify store must-dos for eCommerce success? Check out our <a href="/blog/black-friday-shopify-checklist-and-tips" target="_blank" rel="noreferrer">Black Friday Checklist and Tips</a>.</p>
                <p>By checking off these items, you’re preparing yourself for a smooth Black Friday and setting your Shopify store up to convert that rush of shoppers into real sales. Good luck and happy selling!</p>
            </section>
            <Contact heading="Get in touch" subheading="If you need any help getting your store primed and ready for its best weekend ever, let’s chat!" />
        </Layout>
    )
};

export const query = graphql`
  query {
    file(relativePath: { eq: "blog/black-friday-sign.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Article