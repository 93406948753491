import * as React from 'react';
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout.js';
import PageHeading from '../components/page-heading.js';
import '../styles/meet-the-team.scss';

const MeetTheTeam = ({ data }) => {

    const teamMembers = [{
        name: "James",
        role: "Founder",
        image: data.allFile.nodes.find(node => node.name === "james") ? data.allFile.nodes.find(node => node.name === "james").childImageSharp.gatsbyImageData : null,
        blub: "James started his career in Digital Marketing before training as a Web Developer so he always has performance and data in mind when building and optimising websites."
    },
    {
        name: "James",
        role: "Founder",
        image: data.allFile.nodes.find(node => node.name === "james") ? data.allFile.nodes.find(node => node.name === "james").childImageSharp.gatsbyImageData : null,
        blub: "James started his career in Digital Marketing before training as a Web Developer so he always has performance and data in mind when building and optimising websites."
    },
    {
        name: "James",
        role: "Founder",
        image: data.allFile.nodes.find(node => node.name === "james") ? data.allFile.nodes.find(node => node.name === "james").childImageSharp.gatsbyImageData : null,
        blub: "James started his career in Digital Marketing before training as a Web Developer so he always has performance and data in mind when building and optimising websites."
    },
    {
        name: "James",
        role: "Founder",
        image: data.allFile.nodes.find(node => node.name === "james") ? data.allFile.nodes.find(node => node.name === "james").childImageSharp.gatsbyImageData : null,
        blub: "James started his career in Digital Marketing before training as a Web Developer so he always has performance and data in mind when building and optimising websites."
    }]

    return (
        <Layout background="stone">
            <PageHeading h1="Meet The Team" h2="About Us" blurb="We’re a small but mighty team of multi-disciplined developers, designers, growth and CRO experts. You’ll see no Account Managers because we’re all people people." />
            <section className="ff team">
                {teamMembers.map((member, index) => {
                    return (
                        <div className="member" key={index}>
                            <div className="image">
                                <GatsbyImage image={member.image} alt={member.name} aspectRatio={1} height={300} />
                            </div>
                            <div className="meta">
                                <span className="ff-bold">{member.name}</span><span className="ff slash">/</span><span className="ff">{member.role}</span>
                            </div>
                            <div>
                                <p className="ff blurb">{member.blub}</p>
                            </div>
                        </div>
                    )
                })}
            </section>
        </Layout>
    )
}

export default MeetTheTeam

export const query = graphql`
    query {
        allFile(filter: { sourceInstanceName: { eq: "assets" } }) {
            nodes {
                name
                relativePath
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED, width: 1000)
                }
            }
        }
    }
`;