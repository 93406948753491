import React from 'react';
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import PageHeading from '../../components/page-heading.js';
import '../../styles/article.scss';
import Contact from '../../components/contact.js';

const Article = ({data}) => {
    return (
        <Layout background="stone">
            <SEO 
              title="Black Friday Checklist and Tips for Shopify Plus Ecommerce Success"
              description="Discover our last minute must-dos to ensure ecommerce success for your Shopify Plus store this Black Friday"
              image={data.file.childImageSharp.gatsbyImageData}
              article={true}/>
            <PageHeading h1="Black Friday Checklist and Tips for Shopify Plus Stores" h2="Blog" blurb="Discover our last minute must-dos to ensure ecommerce success for your Shopify Plus store this Black Friday" />
            <section className="ff article">
                <GatsbyImage image={data.file.childImageSharp.gatsbyImageData} alt="Off-the-shelf Shopify Themes: The pros and cons" />
            </section>
            <section className="ff article">
                <h3>Run a Page Insights Report 🚀</h3>
                <p>A slow loading website increases the chances of users leaving your store before purchasing so not only is a fast store imperative for boosting your conversion rate this Black Friday, but you'll also be awarded SEO points and rank higher for your targeted keywords. Organic traffic is often some of the most high-converting, and demonstrates the interconectedness between SEO, web development and CRO.</p>
                <ul>
                    <li>Use Google PageSpeed Insights to assess your website performance.</li>
                    <li>Ensure you’re passing Core Web Vitals for mobile on your homepage, key product
                    pages, and any BFCM landing pages.</li>
                    <li>Run this check a few weeks in advance to allow time for any necessary optimisations.</li>
                </ul>
                <h3>Check Google Search Console for URL Issues 📈</h3>
                <p>Any problematic URLs across your store will be dragging down your SEO efforts and harming your ability to win high-converting organic traffic. Check the health of your website in your Google Search Console to identify quick wins.</p>
                <ul>
                    <li>Identify and fix any problematic URLs on your site.</li>
                    <li>Aim to do this a couple of weeks before Black Friday so Google has time to crawl and re-index your site ahead of the event.</li>
                    <li>Run this check a few weeks in advance to allow time for any necessary optimisations.</li>
                </ul>
                <h3>Device and Browser Testing 💻</h3>
                <p>Quite often we see that around 80% of traffic will be accounted for by the top 5 devices and browsers your users visit your store with. Any snags on these devices could be the difference between a successful Black Friday, and well, an unsuccessful one. Somewhat obviously, these are popular devices so ask around the office if you're finding yourself needing to test a Samsung mobile with Chrome, for instance.</p>
                <ul>
                    <li>Use Google Analytics to pull a report on revenue by device and browser to
                    understand your audience’s preferences.</li>
                    <li>Test the complete customer journey on at least the top 5 devices and browsers
                    to ensure smooth user experiences.</li>
                </ul>
                <h3>Create a Black Friday Theme 🚧</h3>
                <p>You want to avoid making as many changes to your store as you can leading up to Black Friday as every change is the possibility of a introducing a problem - even a typo on a key part of your site will hurt conversion rate! We recommend creating a Black Friday Theme ahead of time which you can configure and prepare "in the wings". Crucially, this allows you to test (see previous tip) your Theme without it being live. And then when it's time for your Black Friday campaign to start, all you need to do is publish the theme and you're done. This also means on the other side of Black Friday, you can "turn off" your campaign in exactly the same manner. Easy.</p>
                <ul>
                    <li>Set up a new BFCM theme in advance with all your content ready to go.</li>
                    <li>When it’s time to launch, simply publish the theme, and your BFCM website is live.</li>
                    <li>This method also allows you to quickly disable the BFCM campaign with one click
                    once the event is over.</li>
                </ul>
                <h3>Prepare a Backup Theme 🔄</h3>
                <p>Whilst not recommended, you might need to make changes your wesbite over the weekend. If you find yourself in this situation for whatever reason, we highly recommend creating a back up theme so if, worst come the worst, there's a problem, you can quickly revert back and minimise any damage to your weekend's performance. Before making any changes, duplicate your live theme and have it ready should you need it.</p>
                <ul>
                    <li>Have a backup theme ready before the weekend in case you need to make
                    last-minute changes.</li>
                    <li>If any issues arise, you can quickly revert to the backup without risking downtime
                    or lost revenue.</li>
                </ul>
                <h3>Cart Abandonment Emails 🛒</h3>
                <p>You're expecting large volumes of high-purchase-intent users hitting your store over Black Friday weekend but many of these users will add items to their cart only to get disctracted or leave them there to mull over. If you don't have cart abandonment emails set up, do that first. Shopify has an in-built solution you can find in your Notifications settings, but most of our client favour the additional power of Klaviyo. If you're running a sale, remind users that the price of the items in their cart won't last forever to encourage them to return and complete their purchase.</p>
                <ul>
                    <li>If not already active, ensure your cart abandonment emails are ready to capture
                    lost sales.</li>
                    <li>Include urgency in these emails, reminding customers that the items in their
                        cart are only on sale until the end of BFCM, encouraging them to complete
                        their purchase.
                    </li>
                </ul>
                <p>We appreciate there's a lot to think about running up to Black Friday weekend so if you need any help getting your store primed and ready for it's best weekend ever, let’s chat!</p>
            </section>
            <Contact heading="Get in touch" subheading="If you need any help getting your store primed and ready for its best weekend ever, let’s chat!" />
        </Layout>
    )
};

export const query = graphql`
  query {
    file(relativePath: { eq: "blog/speed-report.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default Article