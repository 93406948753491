import React, {useState} from 'react';
import SectionHeader from './section-header';
import SpeechBubble from '../assets/icons/icon-speech.svg';

import '../styles/form.scss';
import '../styles/lead-magnet.scss';

const LeadMagnet = ({ heading, subheading }) => {

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [formState, setFormState] = useState({name:"", email:"", company:"", website:"", message:""})

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&")
    }

    const submit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
              "form-name": form.getAttribute("name"),
              ...formState
            })
        }).then(() => {
            setFormSubmitted(true)
            form.reset();
            window.dataLayer?.push({
                event: 'form_interaction',
                eventAction: 'submit'
            })
        }).catch(error => console.error(error))
    }

    const handleChange = (e) => {
        setFormState(prevState => {
            let form = prevState;
            form[e.target.getAttribute("name")] = e.target.value;
            return form
        })
    }

    return (
        <section className="ff lead-magnet" id="lead-magnet">
            <SectionHeader heading={heading} subheading={subheading} />
            <div className="copy">
                <p className="ff">Shopify  say that a 1s improvement in page load speed can increase your conversion rate by up to 27% and with more shoppers predicted to spend more on mobile in 2025 than ever before, page speed has never been more important for increasing sales. That’s why we’re offering a free site speed audit to set up your Shopify store for success this year.</p>
                <p className="ff">Since we’re a small team we can only offer 5 free audits in January so don’t miss out!</p>
            </div>
            <form 
                name="lead-magnet"
                method="POST"
                action="/"
                className="ff ff-form" 
                onSubmit={submit}   
                onChange={handleChange}
                data-netlify-honeypot="bot-field"
                netlify="true">
                <input type="hidden" value="contact" name="bot-field" />
                <div className='input small'>
                    <label htmlFor="name">Name</label><input id="name" type="text" name="name" required/>
                </div>
                <div className='input small'>
                    <label htmlFor="company">Company</label><input id="company" type="text" name="company" />
                </div>
                <div className='input large'>
                    <label htmlFor="email">Email</label><input id="email" type="email" name="email" required/>
                </div>
                <div className='input large'>
                    <label htmlFor="website">Website</label><input id="website" type="text" name="website" />
                </div>
                <button className="ff ff-tertiary" type="submit">
                    <SpeechBubble />
                    <span>{formSubmitted ? 'Speak Soon :)' : 'Claim'}</span>
                </button>
            </form>


        </section>
    )
}

export default LeadMagnet