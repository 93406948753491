import React from 'react';
import { graphql } from "gatsby";
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import PageHeading from '../../components/page-heading.js';
import '../../styles/article.scss';
import '../../styles/case-study.scss';
import Contact from '../../components/contact.js';

const CaseStudy = ({data}) => {
    return (
        <Layout background="stone">
            <SEO 
              title="Jubel: A Shopify Case Study" 
              description="How 54 Bit Studios helped Jubel optimise their website for scalability, performance and enhanced customer engagement."
              image="../assets/clients/jubel/jubel-beer.webp"
              article={true}/>
            <PageHeading h1="Jubel: A Shopify Case Study" h2="Case Study" blurb="How 54 Bit Studios helped Jubel optimise their website for scalability, performance and enhanced customer engagement." />
            <section className="ff article">
            <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} alt="Jubel beer glass" className='case-study-hero' />
                <h3>Overview</h3>
                <p>Jubel Beer is a unique beer brand inspired by the après-ski experience in the Alps. The  company’s roots trace back to its Founder, Jesse Wilson’s discovery of Demi Pêche, a peach flavored lager, during a university ski trip. Determined to bring that alpine magic to the UK,  Jubel Beer launched its distinctive beers cut with fruit in 2018 with a mission to create joy and  euphoria comparable to the après-ski vibe. With sustainability at its core, Jubel Beer strives to  “protect the off-piste” and leave no trace for future generations.</p>
                <p>As a growing business with 40 employees and a premium brand image, Jubel Beer needed a  website that reflected its evolving identity while aligning with its core values and high-quality  offerings. They needed a reliable partner to meet their growing demand and turned to our team  at 54 Bit Studios to help revamp their digital presence.</p>
                <h3>Challenge</h3>
                <p>Jubel Beer faced three main challenges with its existing website:</p>
                <ul className="numbered">
                    <li><strong>Outdated Design:</strong> Their old website lacked the premium, aspirational feel that matched their brand  evolution. It also fell short of delivering the bold, vibrant energy synonymous with the  après-ski experience.</li>
                    <li><strong>Limited Flexibility:</strong> The previous website was complex and didn’t allow Jubel Beer full control of their website.  “We had no flexibility. We couldn’t update things, add, or remove sections easily because it  had been built in quite a weird and complex way.” This slowed down their ability to adapt to  new campaigns or branding updates.</li>
                    <li><strong>Sustainability Showcase:</strong> The old site did not adequately highlight the company’s significant sustainability efforts, a key pillar of their brand.</li>
                </ul>
            <GatsbyImage image={data.image.childImageSharp.gatsbyImageData} alt="Jubel party" className='body-image' />
                <h3>Solution</h3>
                <p>Jubel Beer collaborated with 54 Bit Studios to tackle these challenges. The project spanned  several months and focused on crafting a bespoke website that met Jubel Beer’s needs.</p>
                <p>Key solutions implemented include:</p>
                <ul className="numbered">
                    <li><strong>A Bold, Premium Website Design:</strong> Jubel had a very specific brief; “Bold colors, dopamine hits here and there... It’s all about  bringing the Après-ski experience to everyday life and giving that feeling of joy and  adrenaline rush.” We worked closely with Jubel Beer to deliver a website that captured  this adrenaline, joy, and energy of the après-ski experience.</li>
                    <li><strong>Enhanced Flexibility:</strong> The new site was built with a modular design, allowing the Jubel Beer team to easily  update sections, change text, and upload new imagery without external support.</li>
                    <li><strong>Showcasing Sustainability:</strong> A comprehensive sustainability section was developed to highlight Jubel Beer’s  sustainability mission; “we’ve got a sustainability mission to protect the off-piste, leaving  no tracks or trace for those coming after us. Capturing this in the new website was a  massive upgrade”.</li>
                    <li><strong>Innovative Features:</strong> A ‘Limited Drops’ page was introduced to create excitement around exclusive beer  releases, this includes “a unique sales option where we release limited-edition products  for 24 hours, accessible through a password-protected vault. The animation for the vault  opening is really cool and makes it feel premium and exclusive”.</li>
                    <li><strong>Transparent Communication:</strong> We established a dedicated Slack channel for real-time communication and held weekly  video calls to ensure smooth progress, Andy said he was “super impressed” with our  responsiveness.</li>                
                </ul>
            </section>
            <section className="ff article">
                <h3 className="ff">Results and benefits</h3>
                <p>The collaboration resulted in a significant transformation of Jubel Beer’s online presence. Key outcomes include:</p>
                <ul className="numbered">
                    <li><strong>Premium Brand Experience:</strong> The new website reflects the brand’s growth and aspiration, elevating Jubel Beer’s digital  presence to match its premium positioning. Andy states that “before, we felt like a small  business with our website. Now, it feels like a proper company. The whole site feels so  much more premium. That’s the biggest impact 54 Bit Studios made for us”.</li>
                    <li><strong>Improved Usability and Flexibility:</strong> Jubel Beer can now easily manage and update their website in-house, saving time and  resources. “We now have the freedom to tweak stuff without needing to go back to James  and the team” Andy noted.</li>
                    <li><strong>Engaging Features:</strong> The Limited Drops Page became a standout addition, driving excitement and exclusivity  for limited-edition releases. The unique vault-style animation added a touch of creativity  in the customer experience.</li>
                    <li><strong>Sustainability Focus:</strong> The revamped sustainability section effectively communicates Jubel Beer’s  environmental and social initiatives, resonating with their eco-conscious consumers.</li>
                    <li><strong>Exceptional Communication:</strong> Our seamless and responsive collaboration made the project enjoyable and stress-free.  “We had a Slack channel for day-to-day questions and weekly video calls. They were  super responsive and on hand during launches to address any teething issues.”</li>
                </ul>
                <GatsbyImage image={data.website.childImageSharp.gatsbyImageData} alt="Jubel website" className='body-image' />
                <h3 className="ff">Key Takeaways</h3>
                <ul className="numbered">
                    <li><strong>Collaboration and Creativity:</strong> Our team at 54 Bit Studios succeeded in turning Jubel’s bold  visions into reality while maintaining clear and transparent communication.</li>
                    <li><strong>Enhanced Brand Value:</strong> The website now reflects Jubel Beer’s position as a high-quality,  aspirational brand.</li>
                    <li><strong>Scalable and Flexible Solution:</strong> The modular design ensures Jubel Beer can adapt quickly to  future campaigns and business needs.</li>
                </ul>
            </section>
            <Contact heading="Get in touch" subheading="If you're looking for a skilled and trusted partner to help you grow your online business, let’s chat!" />
        </Layout>
    )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "clients/jubel/jubel-beer.webp" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    image: file(relativePath: { eq: "clients/jubel/jubel-party.webp" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    website: file(relativePath: { eq: "clients/jubel/website.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1000
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`;

export default CaseStudy