import React, {useState} from 'react';
import '../styles/services.scss';
import { StaticImage } from 'gatsby-plugin-image';

import SectionHeader from './section-header';


const Services = ({ heading, subheading }) => {

    const services = [{
        service: 'Web design & development',
        description: 'Using the latest technologies, we craft bespoke Shopify and Shopify Plus stores that not only look stunning and authentically represent your brand but are lighting fast and convert users into customers.'
    },
    {
        service: 'Conversion Rate Optimisation',
        description: 'We monitor and analyse your website performance data to advise and execute improvements to your Shopify store that drive sales. We run tests to find the best performing solutions and then implement them to watch your conversion rate grow.'
    },
    {
        service: 'Search Engine Optimisation',
        description: 'We stay on top of the latest SEO trends and techniques to ensure your website is optimised for search engines to maximise organic traffic. We track and report on your visibility across keyword groups and grow it over time with on-site SEO improvements.'
    },
    {
        service: 'Shopify Migration',
        description: 'We’ve successfully migrated ecommerce websites of varying size and scale to Shopify and Shopify Plus from major platforms like Squarespace, Wordpress, Magento and BigCommerce. '
    },
    {
        service: 'Shopify Integrations',
        description: 'We ensure that any third party apps and integrations are correctly implemented and optimised to work seamlessly with your Shopify store. Including optimising your tracking and analytics to ensure you have the data you need to make informed decisions.'
    },
    {
        service: 'Process Automation',
        description: 'We can help reduce manual tasks around your Shopify store to save you and your team time and money, whilst improving quality and consistency by removing human error.'
    },
]

    return (
        <section className="ff services" id="services">
            <SectionHeader heading="Our services" subheading="Launch & grow" />
            <p className="ff services-intro">Based in London but trusted globally, we offer a unique blend of consultancy and delivery services to advise and execute on your ecommerce strategy whether you are launching a new website or growing your existing one. Our commercially minded team combines expertise in design, development, SEO, CRO, and digital marketing to deliver tailored, data-driven solutions. With a track record of loyalty—no client has ever left us for a competitor—we pride ourselves on building long-lasting partnerships grounded in trust, quality, and results.</p>
            <div className="services-grid">
                {services.map((service, index) => (
                    <div className="service" key={index}>
                        <span className="number">0{index + 1}</span>
                    <h3>{service.service}</h3>
                        <p>{service.description}</p>
                    </div>
                ))}
            </div>
            


        </section>
    )
}

export default Services;