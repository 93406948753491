import React, {useState} from 'react';
import '../styles/section-header.scss';

const SectionHeader = ({heading, subheading}) => {
    return (
        <div className={`section-header ${subheading ? '' : 'no-subheading'}`}>
            <div className="heading">
                <hr />
                <h2 className="ff ff-section-header">{heading}</h2>
            </div>
            <p className="ff subheader">{subheading}</p>
        </div>
    )
}

export default SectionHeader;