import React, {useState, useEffect} from 'react';
import SEO from '../../components/seo.js';
import Layout from '../../components/layout.js';
import Contact from '../../components/contact.js';
import seoImg from './seep-featured.png';



const Seep = () => {

  const [loadStats, setLoadStats] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => setLoadStats(true));
  }, []);



  return (

    <Layout>
      <SEO 
        title="54 Bit Studios | Case Study | Seep" 
        description="How 54 Bit Studios helped Seep drive down page load times and boost conversion rate"
        image={seoImg}
        article={true}/>
        <Contact header="get in touch" subheader="If, like Seep, you're interested in finding out how we can help you and your business, please fill out the form below and we'll be in touch shortly" />
    </Layout>

  )
}

export default Seep
