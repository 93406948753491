import React from 'react';
import '../styles/page-heading.scss';

const PageHeading = ({ h1, h2, blurb }) => {
    return (
        <header className="page-heading">
            <h2 className="ff">{h2}</h2>
            <h1 className="ff">{h1}</h1>
            <p className="blurb">{blurb}</p>
        </header>
    )
}

export default PageHeading