import React, {useState} from 'react';
import axios from 'axios';


import Layout from '../../components/layout.js';
import SEO from '../../components/seo.js';
import PageHeading from '../../components/page-heading.js';

import '../../styles/article.scss';
import '../../styles/careers.scss';


const Careers = () => {

    const [cv, setcv] = useState(null);
    const [video, setVideo] = useState(null);
    const [applicantName, setApplicantName] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleNameChange = (e) => {
        setApplicantName(e.target.value);
    };

    const handleCVChange = (e) => {
        setcv(e.target.files[0]);
    };

    const handleVideoChange = (e) => {
        setVideo(e.target.files[0]);
    };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!cv || !video) return;

    const formData = new FormData();
    formData.append('file', cv);
    formData.append('upload_preset', 'd4m2ggx6');
    formData.append('asset_folder', `recruitment/${applicantName}`);

    try {
      await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_CLOUD_NAME}/auto/upload`,
        formData
      );
    } catch (error) {
        setError(`Upload failed: ${error.message}. Please try again. If the issue persists, please contact us directly at james.dix@54bitstudios.com`);
        setIsSubmitting(false);
        return;
    }

    formData.append('file', video);
    try {
        await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.CLOUDINARY_CLOUD_NAME}/auto/upload`,
          formData
        );

        setSuccess(`Application successful! Thank you for your interest in the role of Junior Web Developer at 54 Bit Studios. We aim to respond to all successful candidates within 5 days of submission. Due to the high volume of applications we receive, we may not be able to respond to all candidates.`);
      } catch (error) {
        setError(`Upload failed: ${error.message}. Please try again. If the issue persists, please contact us directly at james.dix@54bitstudios.com`);
      }
      setIsSubmitting(false);
  };

  return (
    <Layout>
        <SEO title="Careers" />
        <PageHeading h2="Careers" h1="Join our team" blurb="54 Bit Studios was founded to fix the broken relationship between retailer and agency by way of trail-blazing a different path. This path is focussed on a client-first philosophy where developers and decision-makers talk to each other without unnecessary middlemen. We’re not shy in saying that we’re aiming to be the best of the best when it comes to ecommerce development and consultancy for ambitious D2C brands."/>
        <section className="article careers">
            <h3>Junior Web Developer</h3>
            <p>We’re looking to hire a talented front-end Web Developer with an eye for detail and a drive for quality. The successful candidate will deliver exceptional front-end solutions across our entire client portfolio, whilst also holding client-facing responsibilities. Therefore, an ability and desire to communicate directly with clients is essential.</p>
            <p>All candidates must have:</p>
            <ul>
                <li>Experience with front-end technologies including HTML, CSS and Javascript.</li>
                <li>A strong interest in ecommerce</li>
                <li>A passion for crafting beautiful web-based experiences</li>
                <li>Personable skills and outstanding emotional intelligence</li>
                <li>Client-facing experience</li>
            </ul>
            <p>The ideal candidate will have:</p>
            <ul>
                <li>Knowledge of Shopify’s ecosystem</li>
                <li>Experience using Figma for web design</li>
                <li>A strong grasp of git</li>
                <li>Experience developing and working with APIs</li>
            </ul>
            <p>We understand that in order to attract the best talent, we must offer a fantastic working environment and take care of our staff. Here are some of our commitments to employees:</p>
            <ul>
                <li>Very competitive salaries + bonus scheme.</li>
                <li>Unlimited holiday</li>
                <li>Flexible working hours</li>
                <li>A supportive and collaborative working environment</li>
                <li>Company credit for self-development</li>
            </ul>
            <p>As ambitious as it sounds, we want to offer a workplace that no one ever wants to leave.</p>
            <p>If you think you’d be a good fit, fill out an application below.</p>
            <form onSubmit={handleSubmit}>
                <h3>Step 1: Your details</h3>
                <input className="ff ff-input" type="text" placeholder="Name" onChange={handleNameChange} required />
                <h3>Step 2: Upload video</h3>
                <p>Instead of a cover letter, please upload a short video explaining why you would like to work at 54 Bit Studios and why you think you’re right for the role.</p>
                <input type="file" onChange={handleVideoChange} accept="video/*,.pdf" required />
                <p>Please ensure your video is less than 100MB.</p>
                <h3>Step 3: Upload CV</h3>
                <p>Please upload your CV.</p>
                <input type="file" onChange={handleCVChange} accept="video/*,.pdf" required />

                <h3>Step 4: Requirements</h3>
                <div className="checkbox-group">
                    <input type="checkbox" id="visa" name="visa" required />
                    <label htmlFor="visa">I hold a valid work VISA or have the right to work in the UK.</label>
                </div>
                <div className="checkbox-group">
                    <input type="checkbox" id="english" name="english" required />
                    <label htmlFor="english">I am fluent in English.</label>
                </div>
                <div className="checkbox-group">
                    <input type="checkbox" id="uk" name="uk" required />
                    <label htmlFor="uk">I live in the UK and am able to regularly work from our office in London.</label>
                </div>

                <button className={`ff ff-primary ${success ? 'ff-secondary' : ''}`} type="submit" disabled={isSubmitting || success}>
                    {isSubmitting ? (
                        <div className="spinner"></div>
                    ) : (
                        success ? 'Success' : 'Apply'
                    )}
                </button>
                {isSubmitting && <p className="submitting">Hold tight... we're processing your application - this may take up to a minute.</p>}
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
            </form>
        </section>
    </Layout>
  );
};

export default Careers;