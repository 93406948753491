import React, { useState, useRef } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import SectionHeader from './section-header';
import '../styles/video.scss';
import COAT from "../assets/video/COAT-testimonial.mp4";
import PlayInactive from '../assets/icons/icon-play-inactive.svg';
import PlayActive from '../assets/icons/icon-play-active.svg';


const Video = ({ heading }) => {
    const videoRef = useRef(null);
    const [playing, setPlaying] = useState(false);

    const handleVideoClick = () => {
        const video = videoRef.current;
        setPlaying(!playing);
        video.paused ? video.play() : video.pause();
    };

    const handleVideoEnd = () => {
        const video = videoRef.current;
        video.currentTime = 0;
        setPlaying(false);
    }

    return (
        <section className={`ff video ${heading ? '' : 'article'}`} id="testimonial">
            {heading ? (
                <SectionHeader heading={heading} />
            ) : null}
            <div className={`video-container ${playing ? 'playing' : 'paused'}`} onClick={handleVideoClick}>
                <StaticImage src="../assets/video/testimonial-poster.jpg" alt="COAT testimonial" className={`poster`}/>
                <video 
                    ref={videoRef}  
                    playsInline
                    onEnded={handleVideoEnd}>
                    <source src={COAT} type="video/mp4" />
                    <track kind="captions" srcLang="en" />
                </video>
                <PlayInactive />
                <PlayActive />
            </div>
        </section>
    )
};

export default Video