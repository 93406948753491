import React from 'react';
import FistLogo from '../assets/logos/fist.svg';
import '../styles/footer.scss';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='content'>
                <div className='logo'>
                    <FistLogo />
                </div>
                <p className='about'>We're a small team of ecommerce experts who are financially invested in the company. That means no matter if you require coding, SEO, design or conversion rate optimisation - you'll be working with an on-shore professional who cares deeply about delivering beyond your expectations.</p>
            </div>
            <hr></hr>
            <p className='small-print'>54 Bit Studios Ltd is a company registered in England and Wales with registration number 13436200</p>
        </footer>
    )
}

export default Footer